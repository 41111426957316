import React from 'react'
import Twitter from '../../../static/svg/twitter.svg'
import Facebook from '../../../static/svg/facebook.svg'
import Linkedin from '../../../static/svg/linkedin.svg'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${up('md')} {
    display: initial;
    position: absolute;
    right: -90px;
    top: 0;
  }

  ${up('lg')} { right: -120px; }
`

const Item = styled.a`
  margin: 0 10px;
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-child(1) { margin-left: 0; }

  ${up('md')} { margin: 10px 0; }

  &:hover::after { transform: scale(1.2); }

  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.green};
    transform-origin: center;
    transition: transform 1s ${props => props.theme.eases.out};
  }

  svg {
    z-index: 2;
    width: 20px;
    height: 20px;
  }
`

const SocialMedia = ({ pagePath, pageTitle, pageExcerpt }) => {
  return (
    <Wrapper>
      <Item className="social-media__item facebook" target="_blank" rel="noopener noreferrer" aria-label="Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${pagePath}`}><Facebook/></Item>
      <Item className="social-media__item linkedin" target="_blank" rel="noopener noreferrer" aria-label="Linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=${pagePath}&title=${pageTitle}&summary=${pageExcerpt}`}><Linkedin/></Item>
      <Item className="social-media__item twitter" target="_blank" rel="noopener noreferrer" aria-label="Twitter" href={`http://twitter.com/share?text=${pageTitle}&url=${pagePath}`}><Twitter/></Item>
    </Wrapper>
  )
}

export default SocialMedia