import React from 'react'
import SEO from '../components/seo'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'
import SocialMedia from '../components/social-media'
import { getExcerpt, stripHtml } from '../helpers'
import ComponentParser from '../components/ComponentParser'
import Banner from '../components/banner'
import Cover from '../components/cover'
import Suptitle from '../components/suptitle'
import ArrowSvg from '../assets/images/arrow-stroke-left.svg'
import { up } from 'styled-breakpoints'

const Button = styled(Link)`
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  ${up('md')} {
    position: absolute;
    left: -90px;
    top: 0;
    margin-bottom: 0;
  }
  
  ${up('lg')} { left: -120px; }
  
  &:hover::after { transform: scale(1.2); }

  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: ${props => props.theme.colors.green};
    transform-origin: center;
    transition: transform 1s ${props => props.theme.eases.out};
  }

  svg {
    z-index: 2;
    width: 27px;
    height: 30px;
  }
`

const Wrapper = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;

  ${up('lg')} {
    margin-top: 0;
    margin-bottom: 150px;
  }

  h2, h3, h4 {
    font-family: ${props => props.theme.fonts.content};
    font-weight: normal;
    color: ${props => props.theme.colors.green};
    text-transform: uppercase;
  }

  h2 { font-size: 25px; }
  h3 { font-size: 18px; }
  h4 { font-size: 15px; }
`


const SinglePost = (props) => {
  return (
    <>
      <SEO data={props.pageContext.seo} />
      <Cover title={props.pageContext.title} image={props.pageContext.featuredImage.node}></Cover>
      <Wrapper data-scroll-section>
        <Container fluid>
          <Row>
            <Col md={18} lg={14} offset={{md: 3, lg: 5}}>
              <Button to={process.env.GATSBY_PAGE_FOR_POSTS_URI}><ArrowSvg/></Button>
              {props.pageContext.categories.nodes.length && <Suptitle>{props.pageContext.categories.nodes[0].name}</Suptitle>}
              <ComponentParser content={props.pageContext.blocks} fromPost={true}/>
              {props.pageContext.content && <SocialMedia pagePath={props.location.href} pageTitle={props.pageContext.title} pageExcerpt={getExcerpt(stripHtml(props.pageContext.content), 100)} />}
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <Banner {...props.pageContext.options.footerBanner}></Banner>
    </>
  )
}

export default SinglePost